<template>
    <div>
        <v-card :flat="$vuetify.theme.isDark">
            <v-card-title class="mb-2">
                <v-btn
                    :disabled="!adminRole && !documentTypeAddRole"
                    text
                    outlined
                    depressed
                    color="secondary"
                    @click="openAddDialog()"
                >
                    <v-icon class="ml-1">mdi-plus-circle</v-icon>
                    اضافة نوع وثيقة جديد
                </v-btn>
                <v-divider class="mx-4 my-1" vertical> </v-divider>
                <v-spacer></v-spacer>

                <search-in-data-table />
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="items"
                loader-height="10"
                :search="search"
                :loading="loading"
                no-data-text="لا توجد بيانات"
                loading-text="جاري تحميل البيانات"
                class="elevation-1"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
            >
                <template v-slot:[`item.createdDate`]="{ item }">
                    <span class="blue--text">
                        {{ item.createdDate | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.modifiedUserGuid`]="{ item }">
                    <span v-if="item.modifiedUserGuid == null">
                        ليس بعد
                    </span>
                </template>

                <template v-slot:[`item.modifiedDate`]="{ item }">
                    <span class="error--text">
                        {{ item.modifiedDate | formatDate }}
                    </span>
                    <span v-if="item.modifiedDate == null" class="error--text">
                        ليس بعد
                    </span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <!-- v-btn-toggle multiple> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openEditDialog(item)"
                                v-on="on"
                                fab
                                :disabled="!adminRole && !documentTypeEditRole"
                                x-small
                                depressed
                            >
                                <v-icon color="secondary">
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>تعديل</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="deleteItem(item.id)"
                                v-on="on"
                                fab
                                :disabled="
                                    !adminRole && !documentTypeDeleteRole
                                "
                                x-small
                                depressed
                                class="mr-2"
                            >
                                <v-icon color="error">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip>
                    <!-- </v-btn-toggle> -->
                </template>
            </v-data-table>

            <v-divider></v-divider>

            <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                class="py-6"
            >
            </v-pagination>
        </v-card>

        <Dialog ref="dialog" />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SearchInDataTable from "../../components/SearchInDataTable.vue";
import Dialog from "./Dialog.vue";

export default {
    data() {
        return {
            loading: false,
            isfullscreen: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            headers: [
                {
                    text: "نـــوع الوثيقة ",
                    value: "documentName"
                },
                {
                    text: "تاريخ الاضافة",
                    value: "createdDate"
                },
                {
                    text: "المستخدم الذي القام بالاضافة ",
                    value: "createdUser"
                },
                {
                    text: "تاريخ اخر تعديل",
                    value: "modifiedDate"
                },
                {
                    text: "المستخدم الذي القام بأخر تعديل ",
                    value: "modifiedUser"
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    align: "center",
                    sortable: false
                }
            ],
            items: []
        };
    },
    components: {
        Dialog,
        SearchInDataTable
    },

    computed: {
        ...mapState(["search"]),
        ...mapGetters("roles", [
            "adminRole",
            "documentTypeAddRole",
            "documentTypeEditRole",
            "documentTypeDeleteRole"
        ])
    },

    created() {
        this.$eventBus.$on(this.$route.path, () => {
            this.getItems();
        });
        this.getItems();
    },

    methods: {
        getItems() {
            this.loading = true;

            this.$GenericService
                .getAll("documentType/getDocumentTypes")
                .then(res => {
                    this.items = res.data.data;
                    this.loading = false;
                })
                .catch(err => {});
        },

        deleteItem(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`documentType/${id}`)
                        .then(res => {
                            this.getItems();
                            this.$GenericService.swalAlertSuccess();
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.response.data.message}`,
                                color: "error"
                            });
                        });
                }
            });
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
            this.$eventBus.$emit("fill-fields");
        },

        openAddDialog() {
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
        }
    }
};
</script>

<style>
.card {
    position: absolute;
    right: 57%;
    z-index: 1;
    opacity: 0.6;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}
</style>
